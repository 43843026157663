import { Component } from '@angular/core';



@Component({
  selector: 'app-card-success',
  templateUrl: './card-success.component.html',
  styleUrl: './card-success.component.css'
})
export class CardSuccessComponent {

}
