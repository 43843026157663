import { Component, OnInit,  Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService, DataService, MixpanelService } from '../services/index'
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";

@Component({
  selector: 'app-card-loading',
  templateUrl: './card-loading.component.html',
  styleUrl: './card-loading.component.css'
})
export class CardLoadingComponent {
    sub:any;
    confirmData:any;
    res:any;
    isError:boolean;
    errorMessage:any;
    successMessage:any;
    isWaiting:boolean;
    waitingMessage:any;
    api_error:Error;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService,
    private renderer: Renderer2,
    private dataService: DataService,
    private mixpanelService: MixpanelService,

  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(Base64.decode(data));

  
        this.processCard(this.confirmData.data); 

        console.log(this.confirmData)
      

    });

    const screenWidth = window.innerWidth;

    if (screenWidth <= 991) {

      this.loadMobileScript();
    }else{
      this.loadDesktopScript();
    }

  
    this.loadAds();
  }


  processCard(data:any){
   
    console.log(data);

    let pay_id = data.booking_id;
    this.service.confirmV2(pay_id).subscribe(
      response => {
        this.res = response
        console.log(response);
        if(this.res.status =='pending'){
          if(this.res.authorization_url){
            window.location.href = this.res.authorization_url;
          }

        }
      },
      error => {
        console.log(error)
      }
    );

  }


  loadDesktopScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: '8e5c9e81-34db-47a2-a612-8f8b613c86fc', ph: 'yehtu_8e5c9e8134db47a2a6128f8b613c86fc_zone_125480_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '8d0993d8-2d8f-40c9-969e-0160b22bbebd', ph: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '2e47ebaa-4cd3-4517-890d-73002a99a2e2', ph: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadMobileScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '27641268-2842-43d1-8342-87da9ce25b3e', ph: 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'ee955c02-ec3b-4635-9630-27bd45ff897e', ph: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '83f705e0-0010-4481-87be-1348fab1446b', ph: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadAds() {
    const screenWidth = window.innerWidth;

    let adElementId;

    if (screenWidth >= 991) {
        adElementId = 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307';
    } else {
        adElementId = 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307';
    }

    const adElement = document.getElementById(adElementId);

    if (adElement) {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            (window.globalAmlAds = window.globalAmlAds || []).push(function() {
                globalAml.display('${adElementId}');
            });
        `;
        this.renderer.appendChild(adElement, script);
    } else {
        console.error('Ad element not found:', adElementId);
    }
}

}
