import { Component, OnInit,  Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService, DataService, MixpanelService } from '../services/index'
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  sub:any;
  confirmData:any;
  res:any;
  isError:boolean;
  errorMessage:any;
  successMessage:any;
  isWaiting:boolean;
  waitingMessage:any;
  api_error:Error;
  started=0;
  int1:any;
  int2:any;
  int3:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService,
    private renderer: Renderer2,
    private dataService: DataService,
    private mixpanelService: MixpanelService,

  ) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(Base64.decode(data));

      let mixData = {
        route:this.confirmData.search_from + " - " + this.confirmData.search_to,
        busOperator: this.confirmData.operator.name,
        travelDate: this.confirmData.depature_date,
        travelTime: this.confirmData.departure_time,
        totalPassengers:this.confirmData.passengers.length,
        amount:0,
        bookingChannel:"Web",
        isReturnTrip:false,
        source: 'buupassSite',
        role: 'customer',
      }


      this.mixpanelService.track('ReserveSeat', mixData);
      console.log(this.confirmData);
      if(this.confirmData.payment_type =='card'){
        this.processCard(this.confirmData); 
      }else{
        this.confirmMpesa(this.confirmData);      }

    });

    const screenWidth = window.innerWidth;

    if (screenWidth <= 991) {

      this.loadMobileScript();
    }else{
      this.loadDesktopScript();
    }

   
    
    this.loadAds();
  }


  processCard(dataReceived:any){
    let pay_id ='';
    let ticket_ref = ""
    pay_id = dataReceived.data.booking_id;
    ticket_ref =  dataReceived.data.ticket_references
    this.service.confirmV2(pay_id).subscribe(
      response => {
        this.res = response
        console.log(response);
        if(this.res.status =='pending'){

          if(this.res.authorization_url){
            window.location.href = this.res.authorization_url;
          }

        }
      },
      error => {
        console.log(error)
      }
    );

  }
  confirmMpesa(dataReceived:any){

    this.isWaiting=true;
    this.waitingMessage="Processing your payments please wait.....";
        let pay_id ='';
        let ticket_ref = ""
        pay_id = dataReceived.data.booking_id;
        ticket_ref =  dataReceived.data.ticket_references
        if(dataReceived.operator.alias =="moderncoast") {
          this.service.confirmModernCoast(ticket_ref).subscribe(
            response => {
              this.res=response;
              var self = this;
              if(this.res.data.status =='confirmed'){
                this.isWaiting=false;
                this.successMessage="Payment processed successfully";
                let f=Base64.encode(JSON.stringify(dataReceived))
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                this.dataService.changeSuccessMessage(f);

              const mixData = {
                name:self.confirmData.fullname,
                email:self.confirmData.email,
                residence:self.confirmData.from,
                route:self.confirmData.from + self.confirmData.to,
                travelDate: self.confirmData.departure_date,
                Busoperator: self.confirmData.operator,
                amount: self.confirmData.total_fare,
                ticketCount:self.confirmData.passengers.length,
                isReturnUser:false,
                isPromoCodeAdded:"",
                source: 'buupassSite',
                role: 'customer',
              }

              moengage.track_event('Payment Confirmed', {
                'BookingId':self.confirmData.data.booking_id,
                'PassengerName': self.confirmData.fullname,
                'PassengerEmail': self.confirmData.email,
                'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                'PassengerResidence': self.confirmData.search_from,
                'Busoperator': self.confirmData.operator.alias,
                'TotalFare':parseInt(self.confirmData.total_fare),
                'TotalPassengers':self.confirmData.passengers.length
              });



                this.mixpanelService.track('CompletePayment', mixData);
               

                this.router.navigate(['/booking-successful', 'thank-you'])

              }else{
                      this.isWaiting=true;
                      this.waitingMessage="Processing your payments please wait.....";
                        var self = this;
                                  setTimeout(function () {
                                    if(self.started==0){
                                       self.started=1
                                       var i = 1000;
                                       self.int2 = setInterval(function () {
                                        self.confirmMpesa(dataReceived);
                                        i += 1000;
                                        if (i >= 25000){
                                          clearInterval(self.int1);
                                          clearInterval(self.int2);
                                          clearInterval(self.int3);
                                          moengage.track_event('Payment Cancelled', {
                                            'BookingId':self.confirmData.data.booking_id,
                                            'PassengerName': self.confirmData.fullname,
                                            'PassengerEmail': self.confirmData.email,
                                            'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                            'PassengerResidence': self.confirmData.search_from,
                                            'Busoperator': self.confirmData.operator.alias,
                                            'TotalFare':parseInt(self.confirmData.total_fare),
                                            'TotalPassengers':self.confirmData.passengers.length
                                          });
                                          moengage.destroy_session();
                                          window.location.href = '/index.html';
                                        } ;
                                      }, 10000);
                                    }
                                }, 1000);
              }
            },
            error => {
              this.isError=true;
              this.isWaiting=true;
              this.waitingMessage="Processing your payments please wait.....";
              this.api_error=new Error().deserialize(error.error.errors)
              var self = this;
                        setTimeout(function () {
                          if(self.started==0){
                             self.started=1
                             var i = 1000;
                              self.int3 = setInterval(function () {
                              self.confirmMpesa(dataReceived);
                              i += 1000;
                              if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                              } ;
                            }, 10000);
                          }
                      }, 1000);
            }
          );
        } else {
          this.service.confirmV2(pay_id).subscribe(
            response => {
              this.res=response;
              var self = this;
              if(this.res.status =='confirmed'){
                this.isWaiting=false;
                this.successMessage="Payment processed successfully";
                let f=Base64.encode(JSON.stringify(dataReceived))
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                this.dataService.changeSuccessMessage(f);
                this.router.navigate(['/booking-successful', 'thank-you'])
              }else if(this.res.status == 'cancelled' ){
                var self = this;
                this.isWaiting=false;
                this.isError=true;
                // this.errorMessage="Sorry your payment request was cancelled. Please follow the instructions sent via sms to confirm your booking";
                this.errorMessage="Sorry your payment request was cancelled.";

                if (this.res.reason == "validation"){
                    setTimeout(function () {
                      clearInterval(self.int1);
                      clearInterval(self.int2);
                      clearInterval(self.int3);
                      moengage.track_event('Payment Cancelled', {
                        'BookingId':self.confirmData.data.booking_id,
                        'PassengerName': self.confirmData.fullname,
                        'PassengerEmail': self.confirmData.email,
                        'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                        'PassengerResidence': self.confirmData.search_from,
                        'Busoperator': self.confirmData.operator.alias,
                        'TotalFare':parseInt(self.confirmData.total_fare),
                        'TotalPassengers':self.confirmData.passengers.length
                      });
                      moengage.destroy_session();
                      window.location.href = '/index.html';
                    }, 10000);
                }
                          setTimeout(function () {
                            if(self.started==0){
                               self.started=1
                               var i = 1000;
                                self.int1 = setInterval(function () {
                                self.confirmMpesa(dataReceived);
                                i += 1000;
                                if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                                } ;
                              }, 10000);
                            }
                        }, 1000);
              }else{
                      this.isWaiting=true;
                      this.waitingMessage="Processing your payments please wait.....";
                        var self = this;
                                  setTimeout(function () {
                                    if(self.started==0){
                                       self.started=1
                                       var i = 1000;
                                       self.int2 = setInterval(function () {
                                        self.confirmMpesa(dataReceived);
                                        i += 1000;
                                        if (i >= 25000){
                                          clearInterval(self.int1);
                                          clearInterval(self.int2);
                                          clearInterval(self.int3);
                                          moengage.track_event('Payment Cancelled', {
                                            'BookingId':self.confirmData.data.booking_id,
                                            'PassengerName': self.confirmData.fullname,
                                            'PassengerEmail': self.confirmData.email,
                                            'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                            'PassengerResidence': self.confirmData.search_from,
                                            'Busoperator': self.confirmData.operator.alias,
                                            'TotalFare':parseInt(self.confirmData.total_fare),
                                            'TotalPassengers':self.confirmData.passengers.length
                                          });
                                          moengage.destroy_session();
                                          window.location.href = '/index.html';
                                        } ;
                                      }, 10000);
                                    }
                                }, 1000);
              }
            },
            error => {
              this.isError=true;
              this.isWaiting=true;
              this.waitingMessage="Processing your payments please wait.....";
              this.api_error=new Error().deserialize(error.error.errors)
              var self = this;
                        setTimeout(function () {
                          if(self.started==0){
                             self.started=1
                             var i = 1000;
                              self.int3 = setInterval(function () {
                              self.confirmMpesa(dataReceived);
                              i += 1000;
                              if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                              } ;
                            }, 10000);
                          }
                      }, 1000);
            }
          );
        }

  }
  loadDesktopScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: '8e5c9e81-34db-47a2-a612-8f8b613c86fc', ph: 'yehtu_8e5c9e8134db47a2a6128f8b613c86fc_zone_125480_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '8d0993d8-2d8f-40c9-969e-0160b22bbebd', ph: 'yehtu_8d0993d82d8f40c9969e0160b22bbebd_zone_125500_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '2e47ebaa-4cd3-4517-890d-73002a99a2e2', ph: 'yehtu_2e47ebaa4cd34517890d73002a99a2e2_zone_126319_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadMobileScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
    globalAml.defineSlot({z: '3801d166-4c2b-43c7-854b-47c7bf20276e', ph: 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'fb0534f2-11d7-405f-8ca8-3d5384890a13', ph: 'yehtu_fb0534f211d7405f8ca83d5384890a13_zone_125502_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'f5669176-8631-4f53-af38-254d7716719c', ph: 'yehtu_f566917686314f53af38254d7716719c_zone_126318_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '27641268-2842-43d1-8342-87da9ce25b3e', ph: 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: 'ee955c02-ec3b-4635-9630-27bd45ff897e', ph: 'yehtu_ee955c02ec3b4635963027bd45ff897e_zone_126383_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.defineSlot({z: '83f705e0-0010-4481-87be-1348fab1446b', ph: 'yehtu_83f705e00010448187be1348fab1446b_zone_126384_sect_59126_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
    globalAml.singleRequest("yehtu");});
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadAds() {
    const screenWidth = window.innerWidth;

    let adElementId;

    if (screenWidth >= 991) {
        adElementId = 'yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307';
    } else {
        adElementId = 'yehtu_27641268284243d1834287da9ce25b3e_zone_126382_sect_59126_site_52307';
    }

    const adElement = document.getElementById(adElementId);

    if (adElement) {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            (window.globalAmlAds = window.globalAmlAds || []).push(function() {
                globalAml.display('${adElementId}');
            });
        `;
        this.renderer.appendChild(adElement, script);
    } else {
        console.error('Ad element not found:', adElementId);
    }
}
}

