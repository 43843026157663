
<div id="top"></div>
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div id="container _mt-40 bg-buupass ">
    <div class="container">
        <span  class="destinationHeading text-red">Processing your payments please wait.....</span>
        <div class="loader-img u-ib _mt-30">
            <svg *ngIf="isWaiting" xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="152px" height="10px" viewBox="0 0 457 60" xml:space="preserve">
                <g>
                    <circle fill="#007929" cx="-31" cy="30" r="30"/>
                    <circle fill="#329353" cx="-97" cy="30" r="24"/>
                    <circle fill="#64ae7d" cx="-163" cy="30" r="19"/>
                    <circle fill="#c8e2d1" cx="-229.5" cy="30.5" r="13.5"/>
                    <circle fill="#e1efe6" cx="-295" cy="31" r="11"/>
                    <animateTransform attributeName="transform" type="translate" values="61 0;127 0;193 0;259 0;325 0;391 0;457 0;523 0;589 0;655 0;721 0;787 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                </g>
                <g>
                    <circle fill="#007929" cx="488" cy="30" r="30"/>
                    <circle fill="#329353" cx="554" cy="30" r="24"/>
                    <circle fill="#64ae7d" cx="620" cy="30" r="19"/>
                    <circle fill="#c8e2d1" cx="686.5" cy="30.5" r="13.5"/>
                    <circle fill="#e1efe6" cx="753" cy="31" r="11"/>
                    <animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-61 0;-127 0;-193 0;-259 0;-325 0;-391 0;-457 0;-523 0;-589 0;-655 0;-721 0;-787 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                </g>
            </svg>
            <br>
              <span *ngIf="!isWaiting" class="destinationHeading text-danger">{{ errorMessage }}</span>
          </div>
        <div class="message-card">Thank you for your patience.</div>
    </div>
</div>


<app-footer></app-footer>

