<div id="top"></div>
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div id="container _mt-40 bg-buupass ">
    <div class="container">
        <div class="message-card">Please wait while we process your transaction...</div>
        <div class="spinner"></div>
        <div class="message-card">Thank you for your patience.</div>
    </div>
</div>


<app-footer></app-footer>