<div id="top"></div>
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div id="container _mt-40 bg-buupass ">
    <div class="container">
        <div class="icon">✔️</div>
        <div class="title">Payment Successful</div>
        <div class="message-success">A copy of your ticket has been sent to your email and/or phone.</div>
        <a href="/" class="button-success">Go Back</a>
    </div>
</div>


<app-footer></app-footer>