import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlatpickrModule } from 'angularx-flatpickr';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import enke from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
registerLocaleData(enke);
import { AppComponent } from './app.component';
import { HeadermainComponent } from './shared/headermain/headermain.component';
import { HeaderhomeComponent } from './shared/headerhome/headerhome.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { BookingComponent } from './booking/booking.component';
import { ResultsLoaderComponent } from './shared/results-loader/results-loader.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NobusesComponent } from './nobuses/nobuses.component';
import { SuccessComponent } from './success/success.component';
import { CounterDirective } from './directives/counter.directive';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { FromComponent } from './shared/from/from.component';
import { SharedscrollstatusService } from './services/sharedscrollstatus.service';
import { DateSelectComponent } from './shared/date-select/date-select.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NavComponent } from './mobile/nav/nav.component';
import { SearchFormComponent } from './mobile/search-form/search-form.component';
import { HomeMobileComponent } from './mobile/home-mobile/home-mobile.component';
import { DepatureDateComponent } from './mobile/depature-date/depature-date.component';
import { ReturnDateComponent } from './mobile/return-date/return-date.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReviewPayComponent } from './review-pay/review-pay.component';
import { LoginComponent } from './auth/login/login.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { AccountHeaderComponent } from './shared/account-header/account-header.component';
import { MytripsComponent } from './mytrips/mytrips.component';
import { MobileFromComponent } from './mobile/mobile-from/mobile-from.component';
import { TownFilterPipe } from './pipes/town-filter.pipe';
import { MobileToComponent } from './mobile/mobile-to/mobile-to.component';
import { MynotificationsComponent } from './mynotifications/mynotifications.component';
import { TimeFormaterPipe } from './pipes/time-formater.pipe';
import { SortSchedulesPipe } from './pipes/sort-schedules.pipe';
import { HotelsRedirectComponent } from './hotels-redirect/hotels-redirect.component';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { OfflineComponent } from './offline/offline.component';
import { CheckPaymentComponent } from './check-payment/check-payment.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { ProcessRetryComponent } from './process-retry/process-retry.component';
import { RetrySuccessfulComponent } from './retry-successful/retry-successful.component';
import { NameSplitPipe } from './name-split.pipe';
import { WhatsappApiComponent } from './whatsapp-api/whatsapp-api.component';
import { BusBookingComponent } from './bus-booking/bus-booking.component';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';

import { ProcessCardComponent } from './process-card/process-card.component'
import { CardLoadingComponent } from './card-loading/card-loading.component'
import { CardSuccessComponent } from './card-success/card-success.component'


@NgModule({ declarations: [
        AppComponent,
        HeadermainComponent,
        HeaderhomeComponent,
        FooterComponent,
        PaymentsComponent,
        HomeComponent,
        NotFoundComponent,
        SpinnerComponent,
        BookingComponent,
        ResultsLoaderComponent,
        ConfirmComponent,
        NobusesComponent,
        SuccessComponent,
        CounterDirective,
        AboutusComponent,
        ContactusComponent,
        AutofocusDirective,
        DropdownComponent,
        FromComponent,
        DateSelectComponent,
        NavComponent,
        SearchFormComponent,
        HomeMobileComponent,
        DepatureDateComponent,
        ReturnDateComponent,
        ReviewPayComponent,
        LoginComponent,
        MyaccountComponent,
        AccountHeaderComponent,
        MytripsComponent,
        MobileFromComponent,
        TownFilterPipe,
        MobileToComponent,
        MynotificationsComponent,
        TimeFormaterPipe,
        SortSchedulesPipe,
        HotelsRedirectComponent,
        TitleCasePipe,
        OfflineComponent,
        CheckPaymentComponent,
        CountdownTimerComponent,
        ProcessRetryComponent,
        RetrySuccessfulComponent,
        NameSplitPipe,
        WhatsappApiComponent,
        BusBookingComponent,
        FeedbackDialogComponent,
        ProcessCardComponent,
        CardLoadingComponent,
        CardSuccessComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        ScrollingModule,
        FormsModule,
        NgbModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        FlatpickrModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })], providers: [
        SharedscrollstatusService,
        { provide: LOCALE_ID, useValue: "en-US" },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
