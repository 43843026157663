<div class="hidden-sm hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg">
  <app-headerhome ></app-headerhome>
</div>
<div class="theme-page-section theme-page-section-xl">
      <div class="container _p-30">
        <div class="contact-address row block">
                        <div class="col-md-4">
                            <div class="icon-box style5">
                                <i class="soap-icon-phone"></i>
                                <div class="description">
                                    <small>We are on 08:00 AM - 10:00 P.M</small>
                                    <h5>Call :  0715 763 362</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="icon-box style5">
                                <i class="soap-icon-message"></i>
                                <div class="description">
                                    <small>Send us email on</small>
                                    <h5>help&#64;buupass.com</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="icon-box style5">
                                <i class="soap-icon-address"></i>
                                <div class="description">
                                    <small>meet us at</small>
                                    <h5>Nairobi Garage, Ngong Road, Nairobi, Kenya</h5>
                                </div>
                            </div>
                        </div>
                    </div>
        <div class="row _pd-20 _mt-20">
          <div class="col-md-12 ">
            <div class="theme-contact">
              <h5 class="theme-contact-title">Send us a message</h5>
              <div class="row row-col-mob-gap">
                <div class="col-md-5">
                  <div class="form-group theme-contact-form-group">
                    <input class="form-control" type="text" placeholder="Name">
                  </div>
                  <div class="form-group theme-contact-form-group">
                    <input class="form-control" type="text" placeholder="Email">
                  </div>

                </div>
                <div class="col-md-7">
                  <div class="form-group theme-contact-form-group">
                    <textarea class="form-control" rows="4" placeholder="Message"></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                  </div>
                  <div class="col-md-4 ">
                      <a class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow" href="#">Send Your Message</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<app-footer></app-footer>
